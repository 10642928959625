import { AssignmentResources } from "classes/models/assignments/assignment-resource.model";
import { Assignment } from "classes/models/assignments/assignment.model";
import { AssignmentsService } from "classes/models/assignments/assignments.service";

import { Subscription } from "rxjs";
import {
  GoogleDriveResource,
  LinkResource,
} from "~/classes/models/education-resources/education-resource.model";

export const useStudentAssignmentData = (assignmentId: string) =>
  defineStore(`/student/assignments/${assignmentId}/data`, () => {
    const assignment = ref<Assignment>();
    const assignmentResources = ref<AssignmentResources>([]);

    const isInitialized = ref(false);

    const subscription = ref<Subscription | null>(null);
    const assignmentResourcesSubscription = ref<Subscription | null>(null);

    const initializeStudentAssignmentData = async () => {
      if (isInitialized.value) return;

      subscription.value?.unsubscribe();

      subscription.value = AssignmentsService.streamAssignment(
        assignmentId
      ).subscribe((assignmentData) => {
        assignment.value = assignmentData;
      });

      isInitialized.value = true;
    };

    const isAssignmentResourcesStreamInitialized = ref(false);

    watch(assignment, () => {
      if (isAssignmentResourcesStreamInitialized.value) return;

      if (assignment.value == undefined) return;

      const userId = useCurrentUID();

      if (!userId) return;

      assignmentResourcesSubscription.value?.unsubscribe();
      assignmentResourcesSubscription.value =
        AssignmentsService.streamAssignmentResources(
          assignment.value.classroomId,
          assignmentId
        ).subscribe((assignmentResourcesData) => {
          assignmentResources.value = assignmentResourcesData
            .filter((resource) => {
              if (resource.resource.isPublic != true) {
                return false;
              }

              if (resource.resource.type == ResourceType.link) {
                return (
                  (resource.resource as LinkResource).url != "" &&
                  (resource.resource as LinkResource).url != null &&
                  (resource.resource as LinkResource).url != undefined
                );
              }

              if (resource.resource.type != ResourceType.googleDrive) {
                return true;
              }

              return (
                (resource.resource as GoogleDriveResource)
                  .generalPermissionConfig?.type ==
                GoogleDrivePermisisonType.anyone
              );
            })
            .sort((a, b) => {
              return a.resource.displayOrder - b.resource.displayOrder;
            });
        });

      isAssignmentResourcesStreamInitialized.value = true;
    });

    return {
      initializeStudentAssignmentData,
      assignment,
      assignmentResources,
    };
  });
