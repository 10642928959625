import { skipHydrate } from "pinia";

export const useTeacherPesudoStudentLogin = defineStore(
  "/teacher/pseudo-student-login",
  () => {
    const pseudoStudentId = useLocalStorage<string | undefined>(
      "pseudo-student-id",
      undefined
    );

    const initialize = () => {
      const studentId = useRoute().query.studentId as string | undefined;

      if (studentId) {
        pseudoStudentId.value = studentId;
      }
    };

    const hasPseudoStudentId = computed(
      () => pseudoStudentId.value != undefined
    );

    return {
      pseudoStudentId: skipHydrate(pseudoStudentId),
      hasPseudoStudentId,
      initialize,
    };
  }
);
